function Button({ text, link, className, openInNewTab }) {
   const target = openInNewTab ? "_blank" : "_self";
   return (
      <a
         href={link}
         target={target}
         rel={openInNewTab ? "noopener noreferrer" : ""}
         className={`rounded-xl ${className}`}
      >
         {text}
      </a>
   );
}

export default Button;