import React from "react";
import { useEffect, useState } from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Agregar el ícono específico a la biblioteca
library.add(faArrowUp);

function BackToTop() {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setBackToTopButton(true);
            } else {
                setBackToTopButton(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div className="App">
            {backToTopButton && (
                <button
                    className="
                    fixed
                    bottom-20
                    right-6
                    h-10
                    w-10
                    md:h-14
                    md:w-14
                    text-3xl
                    mr-2.5
                    bg-conex
                    rounded-xl
                    flex
                    items-center
                    justify-center
                    "
                    onClick={scrollUp}
                >
                    <FontAwesomeIcon icon="arrow-up" className="text-white w-5 h-6 md:text-white md:w-10 md:h-10" />
                </button>
            )}
        </div>
    );
}

export default BackToTop;
