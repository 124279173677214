import React from 'react';

const Footer = () => {
    return (
        <footer className="lg:hidden block font-semibold bg-zinc-900 text-white p-4 ">
            <div className="text-center text-sm">
            © 2024 ASOCIACIÓN CONEXION, <br />Todos los derechos reservados
            </div>
        </footer>
    );
};

export default Footer;
