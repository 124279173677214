import CookieConsent from "react-cookie-consent";

function CookieMessage() {

  return (

    <>
      <CookieConsent
        debug={false}
        location='bottom'
        style={{
          background: 'rgba(14, 14, 14, 0.75)',
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          color: "white",
          position: "fixed",
          width: "100%",
          zIndex: "999",
          bottom: "0px"
        }}
        buttonStyle={{
          color: '#FFF',
          background: '#004A98',
          fontSize: "14px",
          borderRadius: "7px",
          margin: "10px"
        }}
        buttonText='Acepto'
        expires={365}
      >
        <div style={{
          flex: "1 0 auto",
          margin: "5px"
        }}>
          Utilizamos cookies para optimizar nuestro sitio web y nuestro servicio.
        </div>
      </CookieConsent>
    </>
  );
}

export default CookieMessage;
