import 'tailwindcss/tailwind.css';
import '../src/assets/styles/App.css';
import Landing from './pages/Landing';
import { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";

function App() {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  return (
    <div className='app'>
      {
        loading ?
          <div className='flex justify-center items-center w-full h-screen'>
            <ClipLoader color={'#0D7EB0'} loading={loading} size={45} />
          </div>
          :
          <div>
            <Landing />
          </div>

      }
    </div>
  );
}

export default App;

// se instalo libreria react-consent-cookies 
