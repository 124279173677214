import React, { useState } from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faQuestion);
library.add(faXmark);

function CopyrightModal() {
    const [modal, setModal] = useState(false);
    const toggleModal = () => {
        setModal(!modal)
    };

    if (modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    return (
        <>
            <button onClick={toggleModal} className="btn-modal">
                <FontAwesomeIcon icon="question" />
            </button>
            {modal && (
                <div className="modal">
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="modal-content">
                        <button className="close-modal" onClick={toggleModal}>
                            <FontAwesomeIcon icon="xmark" />
                        </button>
                        <p className="mt-4 font-semibold">© 2024 ASOCIACIÓN CONEXION, <br />Todos los derechos reservados

                        </p>
                    </div>
                </div>
            )}

        </>
    );
}

export default CopyrightModal;