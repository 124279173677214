import React from 'react';
import { useEffect } from 'react';
import conexion from '../assets/images/logo_conexion.png'
import agency from '../assets/images/logo_agency.png'
import academy from '../assets/images/logo_academy.png'
import incubator from '../assets/images/logo_incubator.png'
import Button from '../components/button'
import BackToTop from '../components/BackToTop'
import CookieMessage from '../components/CookieMessage'
import CopyrightModal from '../components/CopyrightModal'
import Footer from '../components/Footer'
import AOS from 'aos';
import 'aos/dist/aos.css';
import useWindowDimensions from '../components/useWindowDimensions';

function Landing() {
    const { width } = useWindowDimensions();

    useEffect(() => {
        AOS.init();
    }, [])
    return (

        <>
            <div className="flex flex-col w-full lg:h-screen lg:flex-row">
                <div className="background-image-1 w-full lg:w-1/2 md:h-screen sm:h-screen pl-6 pr-6 pb-12 h-screen flex items-center justify-center relative">
                    <div className="absolute inset-0 bg-opacity-conexion opacity-80"></div>
                    <div className="relative w-full h-screen flex flex-col items-center justify-center">
                        <div className="flex flex-col items-center justify-center h-full">
                            <img src={conexion} className="xl:h-[100px] lg:h-[90px] md:h-[130px] sm:h-[90px] h-[80px] xl:mb-4 lg:mb-4 md:mb-3 sm:mb-3 mb-3" alt='' />
                            <Button
                                text={"CONEXION EL SALVADOR"}
                                link={" https://conexion.sv/inicio"}
                                className={"btn-conexion text-white bg-conex hover:bg-conex-hover"}
                                openInNewTab={false}
                            />
                        </div>
                        <div className="mt-auto">
                            <p className="xl:text-lg lg:text-lg md:text-xl sm:text-sm text-sm font-bold text-center  text-conex">CONECTAMOS VIDAS CON EL FUTURO</p>
                            <div className="w-full border-t-2 mt-2 mb-2 border-conex"></div>
                        </div>
                    </div>
                </div>
                {/* ////////////////////////// */}
                <div className="lg:w-1/2 lg:flex lg:flex-col lg:h-screen">
                    <div className="background-image-2 h-screen lg:h-1/3 flex items-center justify-center relative">
                        <div className="absolute inset-0 bg-opacity-academy opacity-95"></div>
                        <div className="relative w-full h-full flex flex-col justify-between">
                            <div data-aos={width < 1024 ? "fade-down" : ""} data-aos-easing="linear" data-aos-duration="1500" className="flex flex-col items-center justify-center flex-grow">
                                <img src={academy} className="image" alt='' />
                                <Button
                                    text={"TICONGLE ACADEMY"}
                                    link={"https://ticongle.academy/"}
                                    className={"btn text-white bg-academy hover:bg-academy-hover"}
                                    openInNewTab={true}
                                />
                            </div>
                            <div data-aos={width < 1024 ? "fade-down" : ""} data-aos-easing="linear" data-aos-duration="1500" className="box-one">
                                <p className="slogan">GENERAMOS TALENTO TECNOLÓGICO</p>
                                <div className="border"></div>

                            </div>
                        </div>
                    </div>
                    <div className="background-image-3 h-screen lg:h-1/3 flex items-center justify-center relative">
                        <div className="absolute inset-0 bg-opacity-agency opacity-95"></div>
                        <div className="relative w-full h-full flex flex-col justify-between">
                            <div data-aos={width < 1024 ? "fade-down" : ""} data-aos-easing="linear" data-aos-duration="1500" className="flex flex-col items-center justify-center flex-grow">
                                <img src={agency} className="image" alt='' />
                                <Button
                                    text={"TICONGLE AGENCY"}
                                    link={"https://ticongle.agency/#/"}
                                    className={"btn text-white bg-agency hover:bg-agency-hover"}
                                    openInNewTab={true}
                                />
                            </div>
                            <div data-aos={width < 1024 ? "fade-down" : ""} data-aos-easing="linear" data-aos-duration="1500" className="box-one">
                                <p className="slogan">POTENCIAMOS TU NEGOCIO</p>
                                <div className="border"></div>

                            </div>
                        </div>
                    </div>
                    <div className="background-image-4 h-screen lg:h-1/3 flex items-center justify-center relative">
                        <div className="absolute inset-0 bg-opacity-incubator opacity-90"></div>
                        <div className="relative w-full h-full flex flex-col justify-between">
                            <div data-aos={width < 1024 ? "fade-down" : ""} data-aos-easing="linear" data-aos-duration="1500" className="flex flex-col items-center justify-center flex-grow">
                                <img src={incubator} className="image" alt='' />
                                <Button
                                    text={"TICONGLE INCUBATOR"}
                                    link={"https://incubator.ticongle.com/"}
                                    className={"btn bg-white text-academy hover:bg-gray-300"}
                                    openInNewTab={true}
                                />
                            </div>
                            <div data-aos={width < 1024 ? "fade-down" : ""} data-aos-easing="linear" data-aos-duration="1500" className="box-one">
                                <p className="slogan">DONDE LAS GRANDES IDEAS EMPIEZAN</p>
                                <div className="border"></div>

                            </div>
                        </div>
                    </div>
                </div>

                <BackToTop />
                <CookieMessage />
                <CopyrightModal />
            </div>

            <Footer />
        </>
    );
};

export default Landing;